import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@shopify/polaris/build/esm/styles.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import enTranslations from "@shopify/polaris/locales/en.json";
import {AppProvider} from "@shopify/polaris";

// Logo
import { ASSET_PATH } from './appconfig/Settings';

//Global Window Object
window.helicore = window.helicore || {};
window.cn = function (o) {
    return "undefined" === typeof o || null === o || "" === o.toString().trim()
};
window.cb = function (o) {
    if (o === 'true') {
        return true
    } else {
        return false
    }
};
const theme = {
    colors: {
        topBar: {
            background: "#1C2260",
        },
    },
    logo: {
        width: 120,
        topBarSource:
            ASSET_PATH + "/Images/backoffice/ravel-logo.png",
        // url: userType ? "/admin/dashboard" : "dashboard",
        accessibilityLabel: "RavelLogo",
    },
};

ReactDOM.render(
    <React.StrictMode>
        <AppProvider i18n={enTranslations} theme={theme}>
                <App/>
        </AppProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
