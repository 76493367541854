import moment from 'moment';
import { _pagination } from "./Settings"

export const DEFAULT_TIMEZONE = "America/New_York";

export const convertUTCtoTimeZone = function (date, timeZone = getTimeZone()) {
    return date ? moment.utc(date).tz(timeZone).format('DD MMM YYYY, hh:mm A') : null;
}

export const formatDateTime = function (date) {
    return date ? moment.utc(date).format('DD MMM YYYY, hh:mm A') : null;
}

export const getTimeZone = function () {
    let timezone;
    var pageUrl = window.location.href;
    if (pageUrl.indexOf("/admin/") > 0) {
        timezone = DEFAULT_TIMEZONE;
    } else {
        if (localStorage.getItem('timezone') && localStorage.getItem('timezone') !== null) {
            timezone = localStorage.getItem('timezone');
        }
    }
    return timezone;
}

export const setPagination = function (page = _pagination.page, perpage = _pagination.perpage, totalpages = 0, total = 0) {
    let pagination = _pagination;
    const from = ((page * perpage) - perpage) + 1;
    const to = totalpages === page ? total : perpage * page;
    const hasPrevious = page > 1;
    const hasNext = totalpages > page;
    const showing = total > 0 ? `Showing ${from} to ${to} of ${total} entries` : null;
    pagination = {...pagination, hasNext:hasNext,hasPrevious:hasPrevious,page:page,perpage:perpage,showing:showing}
    return pagination;
}

//Get Columns To Row
export function columnsToRows(objColumns, columnName) {
    return objColumns.map(function (column) {
        return column[columnName];
    })
}
