import React, { useEffect, useState, useCallback } from 'react';
import { Card, Button, Stack, Page } from '@shopify/polaris';
import { useHistory, useNavigate } from 'react-router-dom';
import { MANAGE_FAQ } from '../../config/ApiConstant';
import axiosInstance from "../../config/axiosInstance";
import ToasterToggle from '../../components/Toster';

const AddFaq = () => {

    const [faqData, setFaqData] = useState({});
    const [errors, seterrors] = useState({});
    const [toastMessage, setToastMessage] = useState();
    const [toasttoggle, setToastToggle] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [iserror, setisError] = useState(false);

    const history = useHistory();

    // handleChange
    const handleChange = (e, name) => {
        var value = e.target.value;
        setFaqData({ ...faqData, [name]: value })
    };

    const FaqValidation = () => {
        let isValidate = true;
        let error = {};
        if (window.cn(faqData.title) && !window.cb(faqData.title)) {
            error = { ...error, title: "Enter Title*" };
            isValidate = false;
        } else {
            error = { ...error, title: "" };
        }

        if (window.cn(faqData.description) && !window.cb(faqData.description)) {
            error = { ...error, description: "Enter Description*" };
            isValidate = false;
        } else {
            error = { ...error, description: "" };
        }

        seterrors(error);
        return isValidate;
    }

    //Add data
    const onSubmit = async () => {
        try {
            if (FaqValidation()) {
                setSubmitLoading(true);

                const body = {
                    title: faqData.title,
                    description: faqData.description
                }

                const response = await axiosInstance.post(MANAGE_FAQ, JSON.stringify(body));
                if (response.data) {
                    setFaqData({})
                    setSubmitLoading(false);
                    setToastToggle(true);
                    setToastMessage(response.data.message);
                    setisError(false);
                    seterrors({});
                    setTimeout(() => {
                        history.goBack()
                    }, 2000)
                }
            }
        } catch (error) {
            if (error.response) {
                setSubmitLoading(false);
                setToastToggle(true);
                setToastMessage(error.response.data.error);
                setisError(true);
            }
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <div className="page-content">
                <div className="content-wrapper" style={{ display: "block" }}>
                    <Page title="Add FAQ" fullWidth
                        breadcrumbs={[
                            {
                                content: "Faq",
                                onAction: handleBack,
                            },
                        ]}>
                        <div className="content-inner">
                            <div className="datatable-card">
                                <Card className="mt-3">
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                                        <div className="datatable-header">
                                            <div className="container">
                                                <div className="row mt-1">
                                                    <div className="col-xl-12 col-lg-12 col-12 col-md-12 col-sm-12">
                                                        <div className='row'>
                                                            <div className='col-xl-12 col-lg-12 col-12 col-md-12 col-sm-12'>
                                                                <div className="mb-3">
                                                                    <label htmlFor="exampleInput" className="form-label">Title <span>*</span></label>

                                                                    <input type="text" name="title" value={!window.cn(faqData.title) ? faqData.title : ""} className={!window.cn(errors.title) ? "form-control errorbox" : "form-control"} placeholder="Enter Title"
                                                                        onChange={(e) => handleChange(e, "title")} />
                                                                    {!window.cn(errors.title) ? <p className='errors'>{errors.title}</p> : ""}
                                                                </div>
                                                            </div>

                                                            <div className='col-xl-12 col-lg-12 col-12 col-md-12 col-sm-12'>
                                                                <div className="mb-3">
                                                                    <label htmlFor="exampleInput" className="form-label">Description <span>*</span></label>

                                                                    <textarea type="text" name="description" value={!window.cn(faqData.description) ? faqData.description : ""} className={!window.cn(errors.description) ? "form-control errorbox" : "form-control"} placeholder="Enter Description" onChange={(e) => handleChange(e, "description")} />
                                                                    {!window.cn(errors.description) ? <p className='errors'>{errors.description}</p> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-3"> </div>

                                                <div className="row mb-3">
                                                    <div className="col-lg-12 col-xl-12 col-12 col-md-12 col-sm-12 d-flex justify-content-center">
                                                        <Button primary onClick={() => onSubmit()} loading={submitLoading}>Add</Button>
                                                        {/* <div className="ml-2">
                                                            <Button onClick={() => setFaqData({})}>Clear</Button>
                                                        </div> */}
                                                    </div>
                                                </div>

                                                <div className="row mb-3"> </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            {toasttoggle && (
                                <ToasterToggle
                                    toastmessage={toastMessage}
                                    toastactive={() => setToastToggle(false)}
                                    isErrorMessage={iserror}
                                />
                            )}
                        </div>
                    </Page>
                </div>
            </div>
        </>
    )
}

export default AddFaq