import React, { useState, useEffect, useCallback } from "react";
import { Card, DataTable, Select, Pagination, Page, Stack, Button, Spinner, Filters, Badge } from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../appconfig/Settings";
import { useHistory } from "react-router-dom";
import { columnsToRows, setPagination } from "../../appconfig/AppHelper";
import axiosInstance from "../../config/axiosInstance";
import { GET_USERDATA } from "../../config/ApiConstant";
import { COLUMNS_KEY, UsersColumns } from "../../appconfig/dataTableSetting";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";

const Users = () => {
    document.title = "Users | Ravel";
    const [pageLoad, setPageLoad] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [paginationInner, setPageinationInner] = useState(setPagination());
    const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
    const [perPage, setPerPage] = useState(showing);
    const [paginationActive, setPaginationActive] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        getDataFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getDataFromAPI();
    }, [perPage, searchValue]);

    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    const handleQueryValueRemove = useCallback(() => setSearchValue(''), []);

    const handleChangePerpage = (value) => {
        paginationInner.page = 1;
        setPerPage(value);
    };

    const handleChangePage = (action) => {
        action === "Next" ? paginationInner.page++ : paginationInner.page--;
        getDataFromAPI();
    };

    const history = useHistory();

    const handleViewTransaction = (id) => {
        let path = "/user/view-user-detail"
        history.push(path, { state: id });
        //  history.push({ pathname: path, state: id });
    };

    //api call
    const getDataFromAPI = () => {
        if (pageLoad) setDataTableInnerLoading(true);
        const { page } = paginationInner;

        const requestData = { "page": page, "limit": perPage, "search": searchValue };

        axiosInstance.post(GET_USERDATA, requestData).then((res) => {
            const DataList = res.data.results;

            if (DataList.length === 0) {
                setTableRows([]);
                setPaginationActive(false);
            } else {
                let tableData = [];
                for (let i = 0; i < DataList.length; i++) {
                    let tableRowsAppend = [];

                    tableRowsAppend.push(!window.cn(DataList[i].first_name) && !window.cn(DataList[i].last_name) ? DataList[i].first_name + " " + DataList[i].last_name : "");
                    tableRowsAppend.push(!window.cn(DataList[i].email) ? DataList[i].email : "");
                    tableRowsAppend.push(!window.cn(DataList[i].mobile) ? DataList[i].mobile : "");
                    tableRowsAppend.push(!window.cn(DataList[i].gender) ? DataList[i].gender : "");
                    tableRowsAppend.push(!window.cn(DataList[i].wallet_account) ? DataList[i].wallet_account : "");
                    tableRowsAppend.push(!window.cn(DataList[i].wallet_balance) ? DataList[i].wallet_balance : "");
                    tableRowsAppend.push(!window.cn(DataList[i].account_status) == 1 ? <Badge status="success">Active</Badge> : <Badge status="warning">Inactive</Badge>);
                    tableRowsAppend.push(<Button primary onClick={() => handleViewTransaction(!window.cn(DataList[i].id) ? DataList[i].id : "")}>View</Button>);

                    tableData.push(tableRowsAppend);
                }
                setTableRows(tableData);
                setPaginationActive(true);
            }
            setDataTableInnerLoading(false)
            setPageLoad(true);

            // Get Responce Data and Set respectively
            const { page, total, last } = res.data;
            const pagination = setPagination(page, perPage, last, total);
            setPageinationInner(pagination);
        });
    };

    const Paginate = () => {
        return (
            <>
                <Pagination
                    previousTooltip="Previous"
                    hasPrevious={paginationInner.hasPrevious}
                    onPrevious={() => handleChangePage("Previous")}
                    hasNext={paginationInner.hasNext}
                    nextTooltip="Next"
                    onNext={() => handleChangePage("Next")}
                />
            </>
        );
    };

    return (
        <>
            <Page title="Users List" fullWidth>
                <Card>
                    <Card.Section subdued>
                        <Stack>
                            <Stack.Item fill>
                                <Filters
                                    queryValue={searchValue}
                                    queryPlaceholder="Search Users"
                                    onQueryChange={(value) => handleSearchChange(value)}
                                    filters={[]}
                                    onQueryClear={handleQueryValueRemove}
                                /></Stack.Item>
                            <Stack.Item>
                                <Select
                                    label="Show"
                                    labelInline
                                    onChange={handleChangePerpage}
                                    value={perPage}
                                    options={defaultPageOptions}
                                ></Select>
                            </Stack.Item>
                        </Stack>
                    </Card.Section>
                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(UsersColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(UsersColumns, COLUMNS_KEY.NAME)}
                                    rows={tableRows}
                                    footerContent={
                                        tableRows.length > 0 ? (
                                            <span>{paginationInner.showing}</span>
                                        ) : null
                                    }
                                />
                                {tableRows.length > 0 ? (
                                    <div className="paginate">
                                        <Stack distribution="center">
                                            {paginationActive ? (
                                                <Paginate />
                                            ) : null}
                                        </Stack>
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={8} columns={UsersColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                    </div>
                </Card>

                {/* toaster */}
                {/* {toasttoggle && (
                    <ToasterToggle
                        toastmessage={toastMessage}
                        toastactive={() => setToastToggle(false)}
                        isErrorMessage={isError}
                    />
                )} */}
            </Page>
        </>
    );
};

export default Users;
