import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminAppContainer from "../layouts/AdminAppContainer"

// Web Content 
import HomePage from "../pages/Home/HomePage";
import Users from "../pages/Users/Users";
import PaymentHistory from "../pages/Transactions/PaymentHistory";
import CarPaddy from "../pages/CarPaddy/CarPaddy";
import ViewUserDetail from "../pages/Users/ViewUserDetail";
import HelpAndSupport from "../pages/Help/HelpAndSupport";
import FAQ from "../pages/FAQ/Faq";
import AddFaq from "../pages/FAQ/AddFaq";
import EditFaq from "../pages/FAQ/EditFaq";

export default () => {
    return (
        <AdminAppContainer>
            <Switch>
                <Route exact component={HomePage} path="/home" />
                
                <Route exact component={Users} path="/users" />
                <Route exact component={ViewUserDetail} path= "/user/view-user-detail"/>

                <Route exact component={CarPaddy} path="/carpaddy" />

                <Route exact component={PaymentHistory} path="/paymenthistory" />

                <Route exact component={HelpAndSupport} path="/help&support" />

                <Route exact component={FAQ} path="/faq" />
                <Route exact component={AddFaq} path="/faq/add-faq" />
                <Route exact component={EditFaq} path="/faq/edit-faq" />
            </Switch>
        </AdminAppContainer>
    )
}
