import React, { useState, useEffect, } from "react";
import { Card, DataTable, Page, Spinner, Badge } from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { useHistory } from "react-router-dom";
import { columnsToRows, formatDateTime } from "../../appconfig/AppHelper";
import axiosInstance from "../../config/axiosInstance";
import { GET_USER_DETAIL } from "../../config/ApiConstant";
import { COLUMNS_KEY, CarPaddyUserColumns, PaymentHistoryUserColumns, UserBankColumns } from "../../appconfig/dataTableSetting";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import { ReturnLabelForTransactionType } from "../../helper/helper";

const ViewUserDetail = () => {
    document.title = "Users | Ravel";
    const [pageLoad, setPageLoad] = useState(false);
    const [carTableRows, setCarTableRows] = useState([]);
    const [paymentTableRows, setPaymentTableRows] = useState([]);
    const [bankTableRows, setBankTableRows] = useState([]);
    const [userData, setUserData] = useState({});
    const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);

    useEffect(() => {
        getUserData();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    const history = useHistory();

    const getUserData = async () => {

        const id = history.location.state.state;
        try {

            const requestData = { "user_id": id }
            const response = await axiosInstance.post(GET_USER_DETAIL, requestData);

            const userDetail = response.data.details;
            const carpaymentHistoryList = response.data.carpaddy_list;
            const paymentHistoryList = response.data.payment_history;
            const bankList = response.data.user_banks_add_history;

            if (userDetail) {
                const { first_name, last_name, email, mobile, wallet_balance, gender } = userDetail;
                setUserData({
                    ...userData,
                    first_name: first_name, email: email, last_name: last_name, mobile: mobile, wallet_balance: wallet_balance, gender: gender
                })
            }

            if (carpaymentHistoryList.length === 0) {
                setCarTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < carpaymentHistoryList.length; i++) {
                    let tableRowsAppend = [];

                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].car_reg_no) ? carpaymentHistoryList[i].car_reg_no : "");
                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].car_brand) ? carpaymentHistoryList[i].car_brand : "");
                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].car_model) ? carpaymentHistoryList[i].car_model : "");
                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].car_type) ? carpaymentHistoryList[i].car_type : "");
                    tableRowsAppend.push((carpaymentHistoryList[i].status_type == 'priv') ? 'Private' : "Commercial");
                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].car_year) ? carpaymentHistoryList[i].car_year : "");
                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].gross_amount) ? '₦ ' + carpaymentHistoryList[i].gross_amount : "");
                    tableRowsAppend.push(<div style={{ whiteSpace: "break-spaces" }}>{
                        !window.cn(carpaymentHistoryList[i].order_description) ? carpaymentHistoryList[i].order_description : ""}
                    </div>
                    );
                    tableRowsAppend.push(!window.cn(carpaymentHistoryList[i].createdAt) ? formatDateTime(carpaymentHistoryList[i].createdAt) : "");
                    tableRowsAppend.push((carpaymentHistoryList[i].status == 'delivered') ? <Badge status="success">{carpaymentHistoryList[i].status}</Badge> : (carpaymentHistoryList[i].status == 'Initial') ? <Badge status="secondary">{carpaymentHistoryList[i].status}</Badge> : <Badge status="warning">{carpaymentHistoryList[i].status}</Badge>);
                    tableData.push(tableRowsAppend);
                }
                setCarTableRows(tableData);
            }

            if (paymentHistoryList.length === 0) {
                setPaymentTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < paymentHistoryList.length; i++) {
                    let tableRowsAppend = [];
                    tableRowsAppend.push(
                        !window.cn(paymentHistoryList[i].transaction_id)
                            ? paymentHistoryList[i].transaction_id
                            : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(paymentHistoryList[i].mobile_servicenumber)
                            ? paymentHistoryList[i].mobile_servicenumber
                            : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(paymentHistoryList[i].transaction_type) ? ReturnLabelForTransactionType(paymentHistoryList[i].transaction_type) : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(paymentHistoryList[i].txn_ref) ? paymentHistoryList[i].txn_ref : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(paymentHistoryList[i].amount) ? "₦ " + paymentHistoryList[i].amount : ""
                    );
                    tableRowsAppend.push(
                        (paymentHistoryList[i].transaction_status == 'APPROVED') ? <Badge status="success">{paymentHistoryList[i].transaction_status}</Badge> : <Badge status="critical">{paymentHistoryList[i].transaction_status}</Badge>);
                    tableRowsAppend.push(
                        !window.cn(paymentHistoryList[i].createdAt)
                            ? formatDateTime(paymentHistoryList[i].createdAt)
                            : ""
                    );
                    tableData.push(tableRowsAppend);
                }
                setPaymentTableRows(tableData);
            }

            if (bankList.length === 0) {
                setBankTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < bankList.length; i++) {
                    let tableRowsAppend = [];
                    tableRowsAppend.push(
                        !window.cn(bankList[i].full_name) ? bankList[i].full_name : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(bankList[i].bank_name) ? bankList[i].bank_name : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(bankList[i].bank_code) ? bankList[i].bank_code : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(bankList[i].account_number) ? bankList[i].account_number : ""
                    );
                    tableRowsAppend.push(
                        !window.cn(bankList[i].createdAt) ? formatDateTime(bankList[i].createdAt) : ""
                    );
                    
                    tableData.push(tableRowsAppend);
                }
                setBankTableRows(tableData);
            }

            setDataTableInnerLoading(false)
            setPageLoad(true);
        } catch (error) { }
    }

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <Page title="View User Detail" fullWidth
                breadcrumbs={[
                    {
                        content: "Users",
                        onAction: handleBack,
                    },
                ]}>

                <Card>
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                        <div className="datatable-header">
                            <div className="container">
                                <div className="row mt-1 mb-3">
                                    <div className="col-xl-12 col-lg-12 col-12 col-md-12 col-sm-12">
                                        <div className='row mt-2'>
                                            <div className='col-xl-6 col-lg-6 col-6 col-md-6 col-sm-6'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInput" className="form-label">Name</label>
                                                    <input type="text" name="name" value={!window.cn(userData.first_name) ? userData.first_name + " " + userData.last_name : ""} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                                                </div>
                                            </div>

                                            <div className='col-xl-6 col-lg-6 col-6 col-md-6 col-sm-6'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                                                    <input type="email" name="email" value={userData.email} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                                                </div>
                                            </div>

                                            <div className='col-xl-6 col-lg-6 col-6 col-md-6 col-sm-6'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInput" className="form-label">Mobile</label>
                                                    <input type="text" name="country" value={userData.mobile} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                                                </div>
                                            </div>

                                            <div className='col-xl-6 col-lg-6 col-6 col-md-6 col-sm-6'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInput" className="form-label">Gender</label>
                                                    <input type="text" name="currency" value={userData.gender} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                                                </div>
                                            </div>

                                            <div className='col-xl-6 col-lg-6 col-6 col-md-6 col-sm-6'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInput" className="form-label">Wallet Balance</label>
                                                    <input type="number" name="mobile" value={userData.wallet_balance} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-6 col-md-6 col-sm-6'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInput" className="form-label">Wallet Account</label>
                                                    <input type="text" name="wallet_account" value={userData.wallet_account} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                
                <Card className="mt-3">
                    <div className="row mt-4 mb-2">
                        <h5><b><center>User Car Paddy List</center></b></h5></div>
                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(CarPaddyUserColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(CarPaddyUserColumns, COLUMNS_KEY.NAME)}
                                    rows={carTableRows}

                                />
                                {carTableRows.length > 0 ? (
                                    <div className="paginate">
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={10} columns={CarPaddyUserColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                    </div>
                </Card>

                <Card className="mt-3 mb-2">
                    <div className="row mt-4 mb-2">
                        <h5><b><center>User Payment History</center></b></h5></div>

                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(PaymentHistoryUserColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(PaymentHistoryUserColumns, COLUMNS_KEY.NAME)}
                                    rows={paymentTableRows}
                                />
                                {paymentTableRows.length > 0 ? (
                                    <div className="paginate">
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={6} columns={PaymentHistoryUserColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                    </div>
                </Card>

                <Card className="mt-3 mb-2">
                    <div className="mt-4">
                        <h5><b><center>User Bank Accounts</center></b></h5></div>

                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(UserBankColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(UserBankColumns, COLUMNS_KEY.NAME)}
                                    rows={bankTableRows}
                                />
                                {paymentTableRows.length > 0 ? (
                                    <div className="paginate">
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={5} columns={UserBankColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                    </div>
                </Card>
            </Page>
        </>
    );
}

export default ViewUserDetail;