//Columns Key Global
export const COLUMNS_KEY = {
    NAME: 'name',
    FIELDS: 'field',
    SORTABLE: 'sortable',
    TYPE: 'type',
    LABEL: 'label',
    PROGRESS: "progress",
    COLOR: 'color',
    SORT: 'sort'
};

//Users columns
export const UsersColumns = [
    { name: "Name", type: "text" },
    { name: "Email", type: "text" },
    { name: "Mobile", type: "text" },
    { name: "Gender", type: "text" },
    { name: "Wallet Account" , type: "text"},
    { name: "Wallet Balance" , type: "text"},
    { name: "Status" , type: "text"},
    { name: "Action", type: "text" },
]

//Transcation columns
export const TranscationColumns = [
    { name: "Transaction Id", type: "text" }, 
    { name: "Mobile/Service No.", type: "text" }, 
    { name: "Customer Name", type: "text" }, 
    { name: "Transaction Type", type: "text" },
    { name: "Transaction Reference No.", type: "text"}  ,
    { name: "Amount", type: "text" },
    { name: "Status", type: "text" },
    { name: "Payment Date", type: "text" },
]


// Car Paddy Transactions columns
export const CarPaddyListColumns = [
    { name: "Customer Name", type: "text" },    
    { name: "Registration No", type: "text" },    
    { name: "Brand" , type: "text"},
    { name: "Model" , type: "text"},
    { name: "Car Type" , type: "text"},
    { name: "Vehicle Type" , type: "text"},
    { name: "Year" , type: "text"},
    { name: "Gross Amount" , type: "text"},    
    { name: "Order Description" , type: "text"},
    { name: "Date" , type: "text"},
    { name : "Status", type: "text"}
]


// Transactions Columns
export const TransactionsColumns = [
  { name: "Date", type: "text" },
  { name: "Name", type: "text" },
  { name: "Mobile", type: "text" },
  { name: "Transaction Id", type: "text" },
  { name: "Amount", type: "text" },
  { name: "Transaction Type", type: "text" },
];


// Car Paddy Transactions columns
export const CarPaddyUserColumns = [
    { name: "Registration No", type: "text" },    
    { name: "Brand" , type: "text"},
    { name: "Model" , type: "text"},
    { name: "Car Type" , type: "text"},
    { name: "Vehicle Type" , type: "text"},
    { name: "Year" , type: "text"},
    { name: "Gross Amount" , type: "text"},    
    { name: "Order Description" , type: "text"},
    { name: "Date" , type: "text"},
    { name : "Status", type: "text"}
]

//Payment columns
export const PaymentHistoryUserColumns = [
    { name: "Transaction Id", type: "text" }, 
    { name: "Mobile/Service No.", type: "text" }, 
    { name: "Transaction Type", type: "text" },
    { name: "Transaction Reference No.", type: "text"}  ,
    { name: "Amount", type: "text" },
    { name: "Status", type: "text" },
    { name: "Payment Date", type: "text" },
]

//User Bank columns
export const UserBankColumns = [
    { name: "Name", type: "text" }, 
    { name: "Bank Name", type: "text" },
    { name: "Bank Code", type: "text"}  ,
    { name: "Account No.", type: "text" },
    { name: "Date", type: "text" },
]

//HelpAndSupport columns
export const HelpAndSupportColumns = [
    { name: "Mobile", type: "text" }, 
    { name: "Subject", type: "text" }, 
    { name: "Message", type: "text" }, 
    { name: "Date", type: "text" },
]

//FAQ columns
export const FAQColumns = [
    { name: "Title", type: "text" }, 
    { name: "Description", type: "text" },
    { name: "Date", type: "text" },
    { name: "Action", type: "text" },
]

export const transactionTypeFilterOption = [
    { label: 'Transaction Type : ALL', value: 'All' },
    { label: 'Data Recharge', value: 'databundle' },
    { label: 'Airtime', value: 'telco' },
    { label: 'Electricity', value: 'electricity' },
    { label: 'Cable TV', value: 'cable' },
    { label: 'Education', value: 'education' },
    { label: 'Betting', value: 'Betting' },
    { label: 'Vehicle Renewal', value: 'car_paddy' },
];

export const carPaddyStatusOption = [
    { label: 'Status : ALL', value: 'All' },
    { label: 'Initial', value: 'Initial' },
    { label: 'Received', value: 'received' },
    { label: 'Started', value: 'started' },
    { label: 'Processed', value: 'processed' },
    { label: 'Delivered', value: 'delivered' },
];