import React from 'react';


import {BrowserRouter} from "react-router-dom";
import Admin from "./Admin";
import AuthPages from './Authpages';

export default () => {

    // let user = false;
    
    return(
        <BrowserRouter>
            {window.location.pathname !== '/' ? <Admin /> : null}
            {window.location.pathname == '/' ? <AuthPages /> : null}
        </BrowserRouter>
    )
}
