import React from "react";

import enTranslations from '@shopify/polaris/locales/en.json';
import { Modal, FormLayout,TextStyle, AppProvider } from '@shopify/polaris';

const DeleteConfirmModel = ({ dismissClick, deleteBtnSpinner, submitClick }) => {
    return (
        <>
            <AppProvider i18n={enTranslations}>
                <Modal
                    open={true}
                    onClose={() => dismissClick()}
                    title="Delete"
                    secondaryActions={{
                        content: 'Cancel',
                        onAction: () => dismissClick(),
                    }}
                    primaryAction={[
                        {
                            content: 'OK',
                            onAction: () => submitClick(),
                            loading: deleteBtnSpinner,
                        },
                    ]}
                >
                    <Modal.Section>
                        <FormLayout>
                            <TextStyle variation="negative">Are you sure you want to delete?</TextStyle>
                        </FormLayout>
                    </Modal.Section>
                </Modal>
            </AppProvider>
        </>
    );
}

export default DeleteConfirmModel;