import React, { useState, useEffect, useCallback } from "react";
import { Card, DataTable, Select, Pagination, Page, Stack, Spinner, Filters, Badge } from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../appconfig/Settings";
import { columnsToRows, setPagination, formatDateTime } from "../../appconfig/AppHelper";
import axiosInstance from "../../config/axiosInstance";
import { GET_ALL_PAYMNENT_HISTORY } from "../../config/ApiConstant";
import { COLUMNS_KEY, TranscationColumns, transactionTypeFilterOption } from "../../appconfig/dataTableSetting";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import CustomDatePicker from "../../components/CustomDatePicker";
import moment from "moment";
import Selectreact from 'react-select';
import { ReturnLabelForTransactionType } from "../../helper/helper";

const PaymentHistory = (props) => {
    document.title = "Payment History | Ravel";
    const [pageLoad, setPageLoad] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [paginationInner, setPageinationInner] = useState(setPagination());
    const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
    const [perPage, setPerPage] = useState(showing);
    const [paginationActive, setPaginationActive] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [transactionType, setTransactionType] = useState("All");
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        getDataFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getDataFromAPI();
    }, [perPage, startDate, endDate, transactionType, searchValue]);

    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    const handleQueryValueRemove = useCallback(() => setSearchValue(''), []);

    const handleChangePerpage = (value) => {
        paginationInner.page = 1;
        setPerPage(value);
    };

    const handleChangePage = (action) => {
        action === "Next" ? paginationInner.page++ : paginationInner.page--;
        getDataFromAPI();
    };

    //Handle Date Change
    const handleDateChangeEvent = (start, end) => {
        paginationInner.page = 1;
        //Update State Object
        setStartDate(start);
        setEndDate(end);
    };

    // Handle Select Transactions
    const handleSelectTransactions = (value) => {
        if (!window.cn(value)) {
            setSelectedTransaction(value);
            setTransactionType(value.value);
        } else {
            setSelectedTransaction("");
            setTransactionType("");
        }
    };

    //api call
    const getDataFromAPI = () => {
        if (pageLoad) setDataTableInnerLoading(true);
        const { page } = paginationInner;

        const requestData = { "page": page, "limit": perPage, "start_date": startDate, "end_date": endDate, "transaction_type": transactionType, "search": searchValue };

        axiosInstance.post(GET_ALL_PAYMNENT_HISTORY, requestData).then((res) => {
            const DataList = res.data.results;
            if (DataList.length == 0) {
                setTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < DataList.length; i++) {
                    let tableRowsAppend = [];

                    tableRowsAppend.push(!window.cn(DataList[i].transaction_id) ? DataList[i].transaction_id : "");
                    tableRowsAppend.push(
                        !window.cn(DataList[i].mobile_servicenumber)
                            ? DataList[i].mobile_servicenumber
                            : ""
                    );
                    tableRowsAppend.push(!window.cn(DataList[i].user) ? DataList[i].user.first_name + " " + DataList[i].user.last_name : "");
                    tableRowsAppend.push(
                        !window.cn(DataList[i].transaction_type) ? ReturnLabelForTransactionType(DataList[i].transaction_type) : ""
                    );
                    tableRowsAppend.push(!window.cn(DataList[i].txn_ref) ? DataList[i].txn_ref : "");
                    tableRowsAppend.push(!window.cn(DataList[i].amount) ? '₦ ' + DataList[i].amount : "");
                    tableRowsAppend.push((DataList[i].transaction_status == 'APPROVED') ? <Badge status="success">{DataList[i].transaction_status}</Badge> : <Badge status="critical">{DataList[i].transaction_status}</Badge>);
                    tableRowsAppend.push(!window.cn(DataList[i].createdAt) ? formatDateTime(DataList[i].createdAt) : "");

                    tableData.push(tableRowsAppend);
                }
                setTableRows(tableData);
                setPaginationActive(true)
            }
            setDataTableInnerLoading(false)
            setPageLoad(true);

            // Get Responce Data and Set respectively
            const { page, total, last } = res.data;
            const pagination = setPagination(page, perPage, last, total);
            setPageinationInner(pagination);
        });
    };

    const Paginate = () => {
        return (
            <>
                <Pagination
                    previousTooltip="Previous"
                    hasPrevious={paginationInner.hasPrevious}
                    onPrevious={() => handleChangePage("Previous")}
                    hasNext={paginationInner.hasNext}
                    nextTooltip="Next"
                    onNext={() => handleChangePage("Next")}
                />
            </>
        );
    };

    return (
        <>
            <Page title="Payment History" fullWidth >
                <Card>
                    <Card.Section subdued>
                        <Stack>
                            <Stack.Item fill>
                                <Filters
                                    queryValue={searchValue}
                                    queryPlaceholder="Search Transaction Id / Reference No."
                                    onQueryChange={(value) => handleSearchChange(value)}
                                    filters={[]}
                                    onQueryClear={handleQueryValueRemove}
                                /></Stack.Item>
                            <Stack.Item fill>
                                <Selectreact
                                    placeholder="Select Type"
                                    value={selectedTransaction}
                                    defaultValue={{ label: "Transaction Type : ALL", value: "All" }}
                                    options={transactionTypeFilterOption}
                                    onChange={handleSelectTransactions}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <CustomDatePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(startDate, endDate) =>
                                        handleDateChangeEvent(startDate, endDate)
                                    }
                                >
                                </CustomDatePicker>
                            </Stack.Item>
                            <Stack.Item >
                                <Select
                                    label="Show"
                                    labelInline
                                    onChange={handleChangePerpage}
                                    value={perPage}
                                    options={defaultPageOptions}
                                ></Select>
                            </Stack.Item>
                        </Stack>
                    </Card.Section>
                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(TranscationColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(TranscationColumns, COLUMNS_KEY.NAME)}
                                    rows={tableRows}
                                    footerContent={
                                        tableRows.length > 0 ? (
                                            <span>{paginationInner.showing}</span>
                                        ) : null
                                    }
                                />
                                {tableRows.length > 0 ? (
                                    <div className="paginate">
                                        <Stack distribution="center">
                                            {paginationActive ? (
                                                <Paginate />
                                            ) : null}
                                        </Stack>
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={8} columns={TranscationColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                    </div>
                </Card>
            </Page>
        </>
    );
};

export default PaymentHistory;
