import { Page } from '@shopify/polaris'
import React from 'react'
import { ASSET_PATH } from '../../appconfig/Settings'

const HomePage = () => {
    document.title = "Home | Ravel"
    return (
        <>
            <Page>
                <section
                    id="hero-animated"
                    className="hero-animated d-flex align-items-center"
                >
                    <div
                        className="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
                        data-aos="zoom-out"
                    >
                        <img
                            src={ASSET_PATH + "/Images/backoffice/ravel-logo.png"}
                            className="img-fluid animated"
                        />
                        <p className="mb-0">
                            Hello Admin
                        </p>
                        <h2>
                            Welcome to <span>Ravel</span>
                        </h2>
                    </div>
                </section>
            </Page>
        </>
    )
}

export default HomePage