export const APP_BASE_URL ="https://api.ravelpays.com";
//  export const APP_BASE_URL ="https://378f-2405-201-2000-f136-31d-aa6a-d84b-a81.ngrok-free.app";
export const ASSET_PATH = APP_BASE_URL;
export const IMAGE_BASEURL = ASSET_PATH + "/Images/backoffice/search_image.png";

//default page options
export const defaultPageOptions = [
  { label: "10 entries", value: 10 },
  { label: "25 entries", value: 25 },
  { label: "50 entries", value: 50 },
  { label: "100 entries", value: 100 },
];

//Pagination Object
export const _pagination = {
  hasNext: false,
  hasPrevious: false,
  page: 1,
  perpage: 10,
  showing:null
}

//default pagination showing data
export const showing = 25;

//email Pattern
export const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


//Date picker options
export const datePickerOptions = [
  { label: "Custom", value: "custom" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last7day" },
  { label: "Last 30 days", value: "last30days" },
  { label: "Last 90 days", value: "last90days" },
  { label: "Last month", value: "lastmonth" },
  { label: "Last year", value: "lastyear" },
  { label: "Week to date", value: "weektodate" },
  { label: "Month to date", value: "monthtodate" },
  { label: "Year to date", value: "yeartodate" },
];

// Transaction types
export const transactionTypes = [
  { label : "All", value: "All"},
  { label : "Betting", value: "Betting"},
  { label : "Cable Tv", value: "cable"},
  { label : "Wallet Credit", value: "wallet_credit"},
];

// payment Status
export const paymentStatusOptions = [
  { label : "All", value: "All"},
  { label : "APPROVED", value: "APPROVED"},
  { label : "FAILED", value: "FAILED"},
];