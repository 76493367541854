/********************  START APP API SLUG ************* */

// Prefixed App URL
const ADMIN = "/admin/";

// Authentication
export const LOGIN = ADMIN + "login";

// Users
export const GET_USERDATA = ADMIN + "get-all-users";
export const GET_USER_DETAIL = ADMIN + "get-all-user-history-by-id";

// Car Paddy
export const GET_CARSDATA =  ADMIN + "get-all-carpaddy-list";

// Payment History
export const GET_ALL_PAYMNENT_HISTORY = ADMIN + "get-all-payment-history";

// Help and Support
export const GET_ALL_HELP_SUPPORT_REQUESTS = ADMIN + "get-all-help-supports-requests";

// FAQ
export const GET_ALL_FAQ = ADMIN + "get-all-faqs";
export const MANAGE_FAQ = ADMIN + "manage-faq";
export const DELETE_FAQ = ADMIN + "faq";
export const GET_FAQ_BY_ID = ADMIN + "get-by-id-faqs";

// Transactions
export const GET_TRANSACTIONS = ADMIN + "get-all-users-transactions";