import React, { useState, useEffect, useCallback } from "react";
import { Card, DataTable, Select, Pagination, Page, Stack, Spinner, Filters, Badge } from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../appconfig/Settings";
import { columnsToRows, setPagination, formatDateTime } from "../../appconfig/AppHelper";
import axiosInstance from "../../config/axiosInstance";
import { GET_CARSDATA } from "../../config/ApiConstant";
import { COLUMNS_KEY, CarPaddyListColumns, carPaddyStatusOption } from "../../appconfig/dataTableSetting";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import Selectreact from 'react-select';

const CarPaddy = (props) => {
    document.title = "Car Paddy | Ravel";
    const [pageLoad, setPageLoad] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [paginationInner, setPageinationInner] = useState(setPagination());
    const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
    const [perPage, setPerPage] = useState(showing);
    const [paginationActive, setPaginationActive] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [status, setStatus] = useState({ label: "Status : ALL", value: "All" });

    useEffect(() => {
        getDataFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getDataFromAPI();
    }, [perPage, searchValue, status]);


    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    // Handle Status Type
    const handleStatusType = (value) => {
        if (!window.cn(value)) {
            setStatus(value);
        } else {
            setStatus({ label: "Status : ALL", value: "All" });
        }
    };

    const handleQueryValueRemove = useCallback(() => setSearchValue(''), []);

    const handleChangePerpage = (value) => {
        paginationInner.page = 1;
        setPerPage(value);
    };

    const handleChangePage = (action) => {
        action === "Next" ? paginationInner.page++ : paginationInner.page--;
        getDataFromAPI();
    };

    //api call
    const getDataFromAPI = () => {
        if (pageLoad) setDataTableInnerLoading(true);
        const { page } = paginationInner;

        const requestData = { "page": page, "limit": perPage, search: searchValue, "status": status.value, };

        axiosInstance.post(GET_CARSDATA, requestData).then((res) => {
            const DataList = res.data.results;
            if (DataList.length == 0) {
                setTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < DataList.length; i++) {
                    let tableRowsAppend = [];

                    tableRowsAppend.push(!window.cn(DataList[i].user) ? DataList[i].user.first_name + " " + DataList[i].user.last_name : "");
                    tableRowsAppend.push(!window.cn(DataList[i].car_reg_no) ? DataList[i].car_reg_no : "");
                    tableRowsAppend.push(!window.cn(DataList[i].car_brand) ? DataList[i].car_brand : "");
                    tableRowsAppend.push(!window.cn(DataList[i].car_model) ? DataList[i].car_model : "");
                    tableRowsAppend.push(!window.cn(DataList[i].car_type) ? DataList[i].car_type : "");
                    tableRowsAppend.push((DataList[i].status_type == 'priv') ? 'Private' : "Commercial");
                    tableRowsAppend.push(!window.cn(DataList[i].car_year) ? DataList[i].car_year : "");
                    tableRowsAppend.push(!window.cn(DataList[i].gross_amount) ? '₦ ' + DataList[i].gross_amount : "");
                    tableRowsAppend.push(<div style={{ whiteSpace: "break-spaces" }}>{
                        !window.cn(DataList[i].order_description) ? DataList[i].order_description : ""}
                    </div>
                    );
                    tableRowsAppend.push(!window.cn(DataList[i].createdAt) ? formatDateTime(DataList[i].createdAt) : "");
                    tableRowsAppend.push((DataList[i].status == 'delivered') ? <Badge status="success">{DataList[i].status}</Badge> : (DataList[i].status == 'Initial') ? <Badge status="secondary">{DataList[i].status}</Badge> : <Badge status="warning">{DataList[i].status}</Badge>);
                    tableData.push(tableRowsAppend);
                }
                setTableRows(tableData);
                setPaginationActive(true)
            }
            setDataTableInnerLoading(false)
            setPageLoad(true);

            // Get Responce Data and Set respectively
            const { page, total, last } = res.data;
            const pagination = setPagination(page, perPage, last, total);
            setPageinationInner(pagination);
        });
    };

    const Paginate = () => {
        return (
            <>
                <Pagination
                    previousTooltip="Previous"
                    hasPrevious={paginationInner.hasPrevious}
                    onPrevious={() => handleChangePage("Previous")}
                    hasNext={paginationInner.hasNext}
                    nextTooltip="Next"
                    onNext={() => handleChangePage("Next")}
                />
            </>
        );
    };

    return (
        <>
            <Page title="Car Paddy List" fullWidth>
                <Card>
                    <Card.Section subdued>
                        <Stack >
                            <Stack.Item fill>
                                <Filters
                                    queryValue={searchValue}
                                    queryPlaceholder="Search  Registration No. / Brand / Model"
                                    onQueryChange={(value) => handleSearchChange(value)}
                                    filters={[]}
                                    onQueryClear={handleQueryValueRemove}
                                /></Stack.Item>
                            <Stack.Item >
                                <Selectreact
                                    placeholder="Select Status"
                                    value={status}
                                    defaultValue={{ label: "Status : ALL", value: "All" }}
                                    options={carPaddyStatusOption}
                                    onChange={handleStatusType}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Select
                                    label="Show"
                                    labelInline
                                    onChange={handleChangePerpage}
                                    value={perPage}
                                    options={defaultPageOptions}
                                ></Select>
                            </Stack.Item>
                        </Stack>
                    </Card.Section>
                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(CarPaddyListColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(CarPaddyListColumns, COLUMNS_KEY.NAME)}
                                    rows={tableRows}
                                    footerContent={
                                        tableRows.length > 0 ? (
                                            <span>{paginationInner.showing}</span>
                                        ) : null
                                    }
                                />
                                {tableRows.length > 0 ? (
                                    <div className="paginate">
                                        <Stack distribution="center">
                                            {paginationActive ? (
                                                <Paginate />
                                            ) : null}
                                        </Stack>
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={11} columns={CarPaddyListColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                    </div>
                </Card>
            </Page>
        </>
    );
};

export default CarPaddy;