import CryptoJS from "crypto-js";
import { Buffer } from "buffer";
import { transactionTypeFilterOption } from "../appconfig/dataTableSetting";

// Encryption
export const Encryption = (requestBodyData, PrivetKey) => {

    //create object for cipertext
    const createSendingObj = {
        header: {
            //plain user key
            user_key: PrivetKey
        },
        //request body data 
        body: requestBodyData
    }

    //plain key convert to base 6e
    const base64key = Buffer.from(PrivetKey).toString('base64');

    //encryption using cyper.js aes
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(createSendingObj), PrivetKey).toString();

    //convert cipher text into base 64
    const base64chipertext = Buffer.from(ciphertext).toString('base64');

    //final request obj to send in post api
    const requestObjAPI = {
        headers: {
            app_id: base64key,
        },
        body: base64chipertext,
    }

    //return 
    return requestObjAPI;
}


// Decryption
export const Decryption = (responseData) => {

    //destructure body and app_id from response data
    const { app_id } = responseData.headers;
    const { body } = responseData;

    //convert base64 to plain key
    const Private_key = Buffer.from(app_id, 'base64').toString('ascii');

    //convert cipher into plain
    const base64dcodecipher = Buffer.from(body, 'base64').toString('ascii');

    //decryption algo using cryptojs aes
    const DecryptionCipher = CryptoJS.AES.decrypt(base64dcodecipher, Private_key);

    //convert body jason decode and readble form
    const decryptedData = JSON.parse(DecryptionCipher.toString(CryptoJS.enc.Utf8));

    //return
    return decryptedData;
}


//convert bas64
export const ConvertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const ReturnLabelForTransactionType = (value) => {
   return transactionTypeFilterOption.map((item, index) => {
        if (item.value == value) {
           return item.label;
        }
    })
}


