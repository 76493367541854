import React, { useState } from "react";
import { TextField, Layout, Button, Card, Page, FormLayout } from "@shopify/polaris";

//axios instanse
import axiosInstance from '../../config/axiosInstance';

// Api
import { LOGIN } from "../../config/ApiConstant";

import { Form } from "react-bootstrap";
import { Buffer } from "buffer";
import { ASSET_PATH, emailPattern } from "../../appconfig/Settings";
import ToasterToggle from "../../components/Toster";

export default function Login() {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isError, setErrors] = useState(false);
    const [login, setLogin] = useState({});
    const [toastMessage, setToastMessage] = useState();
    const [toasttoggle, setToastToggle] = useState(false);
    const [iserror, setisError] = useState(false);

    const _onHandleChange = (name, value) => {
        setLogin({ ...login, [name]: value });
    };

    //Validation registration data
    const validate = () => {
        let isValidate = true;
        let error = {};
        if (window.cn(login.email)
            && !window.cb(login.email)) {
            error = { ...error, email: "Enter Email*" };
            isValidate = false;
        }
        else if (!emailPattern.test(login.email)) {
            error = { ...error, email: "Enter Valid Email*" };
            isValidate = false;
        }
        else {
            error = { ...error, email: "" };
        }
        if (window.cn(login.password)) {
            isValidate = false;
            error["password"] = "Enter Password*";
        }
        setErrors(error);
        return isValidate;
    }

    const _submitForm = async () => {
        try {
            if(validate()) {
                setButtonLoading(true);
                const email = login.email.trim();
                let passwordBuff = Buffer.from(login.password).toString('base64');

                const requestData = {
                    email: email,
                    password: passwordBuff
                }

                const response = await axiosInstance.post(LOGIN, requestData);
                if (response.data) {
                    let Data = response.data

                    setToastToggle(true);
                    setToastMessage(Data.message);
                    setisError(false);

                    localStorage.setItem('token', Data.data.token)
                    localStorage.setItem('name', Data.data.data.first_name)

                    setButtonLoading(false);
                    window.location.href = "/home";
                }
            }

        } catch (error) {
            if (error.response) {
                setButtonLoading(false)
                setToastToggle(true);
                setToastMessage(error.response.data.error);
                setisError(true);
            }
        }
    }

    return (
        <Page>
            <div style={{ "transform": "translate(0%,40%)" }}>
                <Layout>
                    <Layout.AnnotatedSection
                        title={<h4>Welcome To Ravel</h4>}
                        description={<h6>Our aim is to help sellers and buyers bridge a gap and enjoy swift financial and payment services.</h6>}
                    >
                        <Layout.Section oneHalf>
                            <Card sectioned>
                                <div style={{ "textAlign": "center", "height": "100px", "width": "200px", "margin": "0 auto", "overflow": "hidden" }}>
                                    <img src={ASSET_PATH + "/Images/backoffice/ravel-logo.png"} style={{ "objectFit": "contain", "width": "100%", "height": "100%" }} alt="ravel-logo" />
                                </div>
                                <br />
                                <Form onSubmit={_submitForm}>
                                    <FormLayout>
                                        <TextField
                                            autoComplete="off"
                                            label="Email"
                                            placeholder="Please enter your email"
                                            value={login.email || ""}
                                            onChange={(value) => _onHandleChange("email", value)}
                                            error={isError.email}
                                        />
                                        <TextField
                                            autoComplete="off"
                                            label="Password"
                                            type="password"
                                            placeholder="Please enter your password"
                                            value={login.password || ""}
                                            onChange={(value) => _onHandleChange("password", value)}
                                            error={isError.password}
                                        />
                                        <Button primary loading={buttonLoading} onClick={_submitForm}>
                                            Submit
                                        </Button>
                                    </FormLayout>
                                </Form>
                            </Card>
                        </Layout.Section>
                    </Layout.AnnotatedSection>
                </Layout>
            </div>
            {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={iserror} />}
        </Page>
    )
}
