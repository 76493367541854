import React, { useState, useEffect, useCallback } from "react";
import { Card, DataTable, Page, Stack, Spinner, Button } from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { columnsToRows, formatDateTime } from "../../appconfig/AppHelper";
import axiosInstance from "../../config/axiosInstance";
import { GET_ALL_FAQ, DELETE_FAQ } from "../../config/ApiConstant";
import { COLUMNS_KEY, FAQColumns } from "../../appconfig/dataTableSetting";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import { useHistory } from "react-router-dom";
import { AddMajor, DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import DeleteConfirmModal from "../../components/DeleteConfirmModel";
import ToasterToggle from '../../components/Toster';

const FAQ = () => {
    document.title = "FAQ | Ravel";
    const [pageLoad, setPageLoad] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteBtnSpinner, setdeleteBtnSpinner] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toasttoggle, setToastToggle] = useState(false);
    const [isError, setisError] = useState(false);

    useEffect(() => {
        getDataFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const history = useHistory();

    //api call
    const getDataFromAPI = async () => {
        if (pageLoad) setDataTableInnerLoading(true);

        await axiosInstance.post(GET_ALL_FAQ).then((res) => {
            const DataList = res.data;

            if (DataList.length == 0) {
                setTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < DataList.length; i++) {
                    let tableRowsAppend = [];

                    tableRowsAppend.push(!window.cn(DataList[i].title) ? DataList[i].title : "");
                    tableRowsAppend.push(<div style={{ whiteSpace: "break-spaces" }}>{
                        !window.cn(DataList[i].description) ? DataList[i].description : ""} </div>
                    );
                    tableRowsAppend.push(!window.cn(DataList[i].createdAt) ? formatDateTime(DataList[i].createdAt) : "");

                    tableRowsAppend.push(
                        <div>
                            <Button primary icon={EditMinor} className="mb-2" size="large" onClick={() => history.push({ pathname: '/faq/edit-faq', state: DataList[i].id })}></Button>
                            <Button primary icon={DeleteMinor} className="mb-2" size="large" onClick={() => deleteData(DataList[i].id)}></Button>
                        </div>
                    );

                    tableData.push(tableRowsAppend);
                }
                setTableRows(tableData);
            }
            setDataTableInnerLoading(false)
            setPageLoad(true);
        });
    };

    //delete
    const deleteData = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteConfirmModal(true);
    }

    //Delete Modal close
    const closeConfirmModal = () => {
        setDeleteId(null);
        setDeleteConfirmModal(false);
    }

    //Delete ConfirmSubmit
    const confirmSubmit = async () => {
        try {
            setdeleteBtnSpinner(true)
            const response = await axiosInstance.delete(DELETE_FAQ + "/" + deleteId)
            if (response.data) {
                setDeleteConfirmModal(false)
                setdeleteBtnSpinner(false)
                setToastToggle(true);
                setToastMessage(response.data.message);
                getDataFromAPI();
            }
        } catch (error) {
            if (error.response) {
                setdeleteBtnSpinner(false);
                setToastToggle(true);
                setToastMessage(error.response.data.error);
            }
        }
    }

    return (
        <>
            <Page title="Frequently Asked Questions" fullWidth >
                <Card>
                    <Stack distribution="trailing">
                        <Stack.Item>
                            <Button primary className="ml-2" size="large" onClick={() => history.push('/faq/add-faq')}>Add FAQ</Button>
                        </Stack.Item>
                    </Stack>
                    <div className="data-wrapper">
                        {pageLoad ? (
                            <div>
                                <DataTable
                                    verticalAlign="middle"
                                    hideScrollIndicator={true}
                                    columnContentTypes={columnsToRows(FAQColumns, COLUMNS_KEY.TYPE)}
                                    headings={columnsToRows(FAQColumns, COLUMNS_KEY.NAME)}
                                    rows={tableRows}
                                />
                                {tableRows.length > 0 ? (
                                    <div className="paginate">
                                        <Stack distribution="center">

                                        </Stack>
                                    </div>
                                ) : <DataTableEmptyState />}
                            </div>
                        ) : (
                            <SkeletonDataTable defaultLength={4} columns={FAQColumns} isImage={false} />
                        )}
                        {dataTableInnerLoading ? (
                            <div className="loading">
                                <Spinner size="large" />
                            </div>
                        ) : null}
                        {toasttoggle && (
                            <ToasterToggle
                                toastmessage={toastMessage}
                                toastactive={() => setToastToggle(false)}
                                isErrorMessage={isError}
                            />
                        )}
                        {deleteConfirmModal ? (
                            <DeleteConfirmModal dismissClick={() => closeConfirmModal()} deleteBtnSpinner={deleteBtnSpinner} submitClick={() => confirmSubmit()} />
                        ) : null}
                    </div>
                </Card>
            </Page>
        </>
    );
};

export default FAQ;